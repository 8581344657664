import { UserModel } from './user.model';
import { EditingGroupModel } from './editing-group.model';

export class FormModel {
    id: string;
    FormIdentifier: string;
    Name: string;
    FormVersion: number;
    Definition: JSON;
    PublishDate: Date;
    IsLastVersion: boolean;
    IsPublished: boolean;
    DaysLimitForDraftSubmissions: number;
    AllowAnonymousSubmission: boolean;
    AllowSubmitResultsToEmail: boolean;
    AllowMultipleSubmissions: boolean;
    AllowExportFormToPdf: boolean;
    AllowDraftsSubmissionsRestoration: boolean;
    AllowsOlderVersionSubmissions: boolean;
    IsLockedForModification: boolean;
    Owner: UserModel;
    EditingGroups: EditingGroupModel[];
}

import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { forkJoin } from 'rxjs';
import * as _ from 'lodash';
import { FormService } from 'src/app/services/form.service';
import { FormModel } from 'src/app/models/form.model';
import { environment } from 'src/environments/environment';
import { TextValues } from 'src/app/shared/text-values';
import { LayoutService } from 'src/app/services/layout.service';
import { Title } from '@angular/platform-browser';

@Component({
    selector: 'app-form-list',
    templateUrl: './form-list.component.html',
    styleUrls: ['./form-list.component.css']
})
export class FormListComponent implements OnInit {
    forms: FormModel[];
    pickedForm: FormModel;
    currentPage: number;
    itemsPerPage = 10;
    baseUrl = environment.webUrl;
    pickedShareFormLink = '';
    loadingForms = true;
    createFormModalOpened = false;
    shareFormModalOpened = false;
    formSettingsModalOpened = false;
    shareFormLinks = {};
    formSettingsDropdownElements = [];

    constructor(private router: Router, private route: ActivatedRoute, private formService: FormService, private title: Title, public layoutService: LayoutService, public textValues: TextValues) { }

    ngOnInit(): void {
        this.title.setTitle(this.textValues.formListPageTitle);
        this.route.queryParams.subscribe(params => {
            if (!params['page']) {
                this.navigateToPage(1);
            } else {
                this.currentPage = +params['page'];
            }
        });
        this.getAllForms();
    }

    deleteForm(form: FormModel): void {
        if (confirm('Are you sure you want to delete this form and all of its versions?')) {
            this.formService.deleteForm(form.FormIdentifier).subscribe(() => this.getAllForms());
        }
    }

    updateFormSettings(form: FormModel): void {
        this.formService.updateForm(form.id, form).subscribe(updatedForm => {
            this.forms = this.forms.map((item, index) => {
                if (item.id === updatedForm.id) {
                    return this.forms[index] = updatedForm;
                }
                return this.forms[index];
            });
        });
        this.closeFormSettingsModal();
    }

    openCreateFormModal(event: MouseEvent): void {
        this.createFormModalOpened = true;
        event.preventDefault();
        event.stopPropagation();
    }

    closeCreateFormModal(): void {
        this.createFormModalOpened = false;
    }

    openShareFormModal(event: MouseEvent, form: FormModel): void {
        this.pickedShareFormLink = this.shareFormLinks[form.id];
        this.shareFormModalOpened = true;
        event.preventDefault();
        event.stopPropagation();
    }

    closeShareFormModal(): void {
        this.shareFormModalOpened = false;
        this.pickedShareFormLink = '';
    }

    openFormSettingsModal(form: FormModel): void {
        this.formSettingsModalOpened = true;
        this.formService.getForm(form.id).subscribe(form => {
            this.pickedForm = form;
        });
    }

    closeFormSettingsModal(): void {
        this.formSettingsModalOpened = false;
        this.pickedForm = undefined;
    }

    navigateToPage(newPage: number): void {
        this.router.navigate([], {
            relativeTo: this.route,
            queryParams: { page: newPage.toString() },
            skipLocationChange: false
        });
    }

    private getAllForms(): void {
        forkJoin([this.formService.getFormsOwnedByUser(), this.formService.getFormsEditableByUser()])
            .subscribe((response) => {
                const [formsResponse, editingGroupsResponse] = response;
                let allForms = formsResponse.data.forms;

                editingGroupsResponse.data.editingGroups.forEach(group => {
                    allForms = allForms.concat(group.Forms);
                });

                this.forms = _.uniqBy(allForms, 'FormIdentifier');
                this.loadingForms = false;
                this.shareFormLinks = this.generateShareFormLinks(this.forms);
            });
    }

    private generateShareFormLinks(forms: FormModel[]): object {
        const shareFormLinks = {};
        for (const form of forms) {
            shareFormLinks[form.id] = `${this.baseUrl}/forms/${form.FormIdentifier}/new-submission`;
        }
        return shareFormLinks;
    }
}

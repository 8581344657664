<app-create-form *ngIf='createFormModalOpened' (closeBtnClicked)='closeCreateFormModal()'></app-create-form>
<div class="flex bg-gray-100">
    <navbar (createFormBtnClicked)='openCreateFormModal()'></navbar>
    <div [ngClass]="{'page-container-navbar-collapsed': layoutService.navbarCollapsed, 'page-container-navbar-not-collapsed': !layoutService.navbarCollapsed}" class='w-full h-screen'>
        <app-page-header></app-page-header>
        <div class="flex p-8 overflow-x-auto md:flex-row">
            <div class="flex w-2/3 order-last space-x-4 text-gray-700 lg:space-x-12 md:order-none">
                <h2 class="text-4xl font-bold leading-10">
                    {{ textValues.submissionsListHeader }}
                </h2>
            </div>
            <div class="flex w-1/3 space-x-10 justify-end">
                <app-searchbar class='w-60' [placeholder]='textValues.submissionSearchPlaceholder'></app-searchbar>
            </div>
        </div>
        <div class="flex">
            <div class="flex w-full lg:px-2 xl:px-1 2xl:px-8 border-gray-200">
                <table class="w-full bg-white divide-y divide-gray-200">
                    <thead>
                        <tr>
                            <th class="p-5">
                                <input type="checkbox">
                            </th>
                            <th class="text-left table-header">
                                {{ textValues.nameSubmissionListColHeader }}
                            </th>
                            <th class="text-center table-header">
                                {{ textValues.dateSubmittedSubmissionListColHeader }}
                            </th>
                            <th class="text-center table-header">
                                {{ textValues.statusSubmissionListColHeader }}
                            </th>
                        </tr>
                    </thead>
                    <tbody *ngIf="submissions?.length > 0" class="divide-y divide-gray-200">
                        <tr
                            *ngFor="let submission of submissions | paginate: { itemsPerPage: itemsPerPage, currentPage: currentPage }">
                            <td class="p-5 text-center">
                                <input type="checkbox">
                            </td>
                            <td class="py-4">
                                <div class="px-4 text-left table-content">
                                    {{ submission.Form.Name }}
                                </div>
                            </td>
                            <td class="py-4">
                                <div class="text-center table-content">
                                    {{ submission.LastSubmissionDate | date: 'MM.dd.yy' }}
                                </div>
                            </td>
                            <td class="py-4">
                                <span
                                    class="flex flex-row items-center justify-center h-8 text-xs font-bold uppercase whitespace-nowrap rounded-full"
                                    [ngClass]="calculateClasses(submission.expirationStatus)">
                                    {{ submission.expirationStatus | messageExpired: submission.daysLeftTillDraftExpire
                                    }}
                                </span>
                            </td>
                            <td class="p-4 flex justify-center items-center space-x-10 2xl:px-0 2xl:space-x-16">
                                <a class="table-link" target='_blank' [class.disabled]="!submission.canResumeDraft"
                                    [routerLink]="['/submissions', submission.id]">
                                    {{ textValues.submissionListResumeBtnTxt }}
                                </a>
                                <a class="table-link" [class.disabled]="!submission.cannotRestoreDraft"
                                    [routerLink]="['/submissions', submission.id]">
                                    {{ textValues.submissionListStartOverBtnTxt }}
                                </a>
                                <a class="table-link" [class.disabled]="!submission.canPreview"
                                    [routerLink]="['/submissions', submission.id]">
                                    {{ textValues.submissionListPreviewBtnTxt }}
                                </a>
                                <a class="table-link" [class.disabled]="!submission.canSubmitNew"
                                    [routerLink]="['/forms', submission.Form.FormIdentifier, 'new-submission']">
                                    {{ textValues.submissionListSubmitNewBtnTxt }}
                                </a>
                            </td>
                        </tr>
                    </tbody>
                </table>
            </div>
        </div>
        <div *ngIf='submissions?.length > itemsPerPage' class='flex justify-center items-center my-5'>
            <pagination-controls class='pagination-ctrl' (pageChange)="navigateToPage($event)"></pagination-controls>
        </div>
    </div>
</div>
<app-create-form *ngIf='createFormModalOpened' (closeBtnClicked)='closeCreateFormModal()'></app-create-form>
<div class='flex overflow-hidden h-screen bg-gray-100'>
    <navbar (createFormBtnClicked)='openCreateFormModal()'></navbar>
    <div [ngClass]="{'page-container-navbar-collapsed': layoutService.navbarCollapsed, 'page-container-navbar-not-collapsed': !layoutService.navbarCollapsed}" class='w-full h-screen'>
        <app-page-header></app-page-header>
        <div class='flex p-8 overflow-x-auto md:flex-row'>
            <div class='flex w-2/3 order-last space-x-4 text-gray-700 lg:space-x-12 md:order-none'>
                <h2 class='text-4xl font-bold leading-10'>
                    {{ formName }}
                </h2>
            </div>
            <div class='flex w-1/3 space-x-10 justify-end'>
                <app-searchbar class='w-60' [placeholder]='textValues.submissionSearchPlaceholder'></app-searchbar>
                <button [routerLink]="['/forms']" class='w-20 h-10 px-6 py-3 text-sm font-bold leading-4 text-white whitespace-nowrap teal-button'>
                    {{ textValues.backBtnTxt }}
                </button>
            </div>
        </div>
        <div class='flex'>
            <div class='flex w-full p-5 lg:px-8 border-gray-200'>
                <table class='w-full bg-white divide-y divide-gray-200'>
                    <thead>
                        <tr>
                            <th class='p-5'>
                                <input type='checkbox'>
                            </th>
                            <th class='text-center table-header'>
                                {{ textValues.hashTag }}
                            </th>
                            <th class='text-center table-header'>
                                {{ textValues.answersListSubmitterColHeader }}
                            </th>
                            <th class='text-center table-header'>
                                {{ textValues.answersListStatusColHeader }}
                            </th>
                            <th class='text-center table-header'>
                                {{ textValues.answersListDateStartedColHeader }}
                            </th>
                            <th class='text-center table-header'>
                                {{ textValues.answersListDateSubmitterColHeader }}
                            </th>
                        </tr>
                    </thead>
                    <tbody *ngIf='submissions?.length > 0' class='divide-y divide-gray-200'>
                        <tr *ngFor="let submission of submissions | paginate: { itemsPerPage: itemsPerPage, currentPage: currentPage }; let i = index" [attr.data-index]='i'>
                            <td class='p-3 text-center'>
                                <input type='checkbox'>
                            </td>
                            <td class='p-3 text-center table-content'>
                                {{ i+1 }}
                            </td>
                            <td class='p-3 text-center table-content'>
                                <div *ngIf='submission.Submitter'>
                                    {{ submission.Submitter.username }}
                                </div>
                                <div *ngIf='!submission.Submitter'>
                                    Anonymous
                                </div>
                            </td>
                            <td class='p-3'>
                                <span class="flex flex-row items-center justify-center h-8 text-xs font-bold uppercase whitespace-nowrap rounded-full"
                                    [ngClass]="calculateClasses(submission.expirationStatus)">
                                        {{ submission.expirationStatus | messageExpired: submission.daysLeftTillDraftExpire }}
                                </span>
                            </td>
                            <td class='p-3'>
                                <div class='text-center table-content'>
                                    {{ submission.FirstSubmissionDate | date: 'short' }}
                                </div>
                            </td>
                            <td class='p-3'>
                                <div class='text-center table-content'>
                                    {{ submission.LastSubmissionDate | date: 'short' }}
                                </div>
                            </td>
                            <td class='p-3 flex justify-center items-center'>
                                <button class='table-button' [disabled]='!submission.canPreview'
                                    [routerLink]="['/submissions', submission.id]">
                                    {{ textValues.submissionListPreviewBtnTxt }}
                                </button>
                            </td>
                        </tr>
                    </tbody>
                </table>
            </div>
        </div>
        <div *ngIf='submissions?.length > itemsPerPage' class='flex justify-center items-center my-5'>
            <pagination-controls class='pagination-ctrl' (pageChange)="navigateToPage($event)"></pagination-controls>
        </div>
    </div>
</div>
import { Injectable } from '@angular/core';

@Injectable({
    providedIn: 'root'
})
export class TextValues {
    // Titles
    public loginPageTitle = 'Inclusive Forms - Login';
    public formListPageTitle = 'Inclusive Forms - Forms';
    public formBuilderPageTitle = 'Inclusive Forms - Form Builder';
    public submissionPageTitle = 'Inclusive Forms - Submissions';
    // Login/Register page
    public loginEmailPlaceholder = 'E-mail';
    public loginPasswordPlaceholder = 'Password';
    public loginLinkTxt = 'Login';
    public loginBtnTxt = 'Login';
    public registerUsernamePlaceholder = 'Name';
    public registerEmailPlaceholder = 'E-mail';
    public registerPasswordPlaceholder = 'Password';
    public registerConfirmPassPlaceholder = 'Confirm Password';
    public registerLinkTxt = 'Register';
    public registerBtnTxt = 'Register';
    public forgotPasswordBtnTxt = 'Forgot Password';
    public forgotPasswordHeader = 'Forgot Password';
    public forgotPasswordEmailPlaceholder = 'E-mail';
    public resetEmailBtnTxt = 'Send Password Reset E-mail';
    public resetEmailModalHeader = 'Password Reset Email Sent To';
    // Page header
    public searchPlaceholder = 'Type to search...';
    public logoutText = 'Logout';
    // Navbar
    public createNewFormBtnTxt = 'Create New Form';
    public createFormModalHeader = 'Create New Form';
    public formsPageNavText = 'Forms';
    public submissionsPageNavText = 'Submissions';
    public answersPageNavText = 'Answers';
    public analyticsPageNavText = 'Analytics';
    public knowledgeBasePageNavText = 'Knowledge Base';
    public accountPageNavText = 'Account';
    // Create form modal
    public createFormInputPlaceholder = 'Form Name';
    public createFormSubmitBtnTxt = 'Create';
    public closeModalBtnText = 'Close';
    // Form settings
    public formSettingsAnonymousSubmissionLbl = 'Anonymous Submission';
    public formSettingsSubmitResultsEmailLbl = 'Submit Results To E-mail';
    public formSettingsMultipleSubmissionsLbl = 'Multiple Submission';
    public formSettingsExportFormPdfLbl = 'Export Form To PDF';
    public formSettingsDraftSubmissionRestorationLbl = 'Drafts Submissions Restoration';
    public formSettingsOlderVersionSubmission = 'Older Version Submission';
    public formSettingsStatusLbl = 'Status';
    public formSettingsPublishedStatusLbl = 'Published';
    public formSettingsFormVersionLbl = 'Form Version';
    public formSettingsDaysLeftDraftSubmission = 'Day Limit For Draft Submission';
    public formSettingsEditingGroupsLabel = 'Editing Groups';
    public saveChangesModalBtnText = 'Save';
    // Form-List
    public formListHeader = 'Forms';
    public formSearchPlaceholder = 'Search Forms...';
    public nameFormListColHeader = 'Name';
    public versionFormListColHeader = 'Version';
    public submissionsFormListColHeader = 'Submissions';
    public datePublishedFormListColHeader = 'Date Published';
    public statusFormListColHeader = 'Status';
    public formListEditBtnTxt = 'Edit';
    public formListShareBtnTxt = 'Share';
    public formListAnswersBtnTxt = 'Answers';
    public publishedFormStatusVal = 'Published';
    public unpublishedFormStatusVal = 'Unpublished';
    public shareFormCopyBtnTxt = 'Copy';
    public shareFormModalHeader = 'Share Form';
    // Form Editor
    public formSettingsBtnTxt = 'Settings';
    // Submission-List
    public submissionsListHeader = 'Submissions';
    public submissionSearchPlaceholder = 'Search Submissions...';
    public nameSubmissionListColHeader = 'Name';
    public dateSubmittedSubmissionListColHeader = 'Date Submitted';
    public statusSubmissionListColHeader = 'Status';
    public submissionListResumeBtnTxt = 'Resume';
    public submissionListStartOverBtnTxt = 'Start Over';
    public submissionListPreviewBtnTxt = 'Preview';
    public submissionListSubmitNewBtnTxt = 'Submit New';
    // Expiration statuses
    public submissionStatusExpired = 'Expired';
    public submissionStatusExpiresToday = 'Expires today';
    public submissionStatusDraft = 'Draft';
    public submissionStatusSubmitted = 'Submitted';
    public submissionStatusExpiresIn = 'Draft - Expires in {{ daysLeft }} days';
    // Answers
    public answersListSubmitterColHeader = 'Submitter';
    public answersListStatusColHeader = 'Status';
    public answersListDateStartedColHeader = 'Date Started';
    public answersListDateSubmitterColHeader = 'Date Submitted';
    // Other
    public loadingLabel = 'Loading...';
    public backBtnTxt = 'Back';
    public hashTag = '#';
}

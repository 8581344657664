import gql from 'graphql-tag';

export const FORM_LIST_INFO = gql`
    fragment formInfo on Form {
        id,
        Name,
        FormVersion,
        FormIdentifier,
        PublishDate,
        IsPublished,
        Owner {
            username
        }
    }
`;

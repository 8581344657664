import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { ApolloQueryResult } from '@apollo/client/core';
import { Observable } from 'rxjs';
import { FormModel } from 'src/app/models/form.model';
import { EditingGroupModel } from 'src/app/models/editing-group.model';
import { FormService } from 'src/app/services/form.service';
import { EditingGroupService } from 'src/app/services/editing-group.service';
import { GraphQLDataResponse } from 'src/app/interfaces/graphql-data-response';
import { TextValues } from 'src/app/shared/text-values';
import { LayoutService } from 'src/app/services/layout.service';
import { Title } from '@angular/platform-browser';

@Component({
    selector: 'app-form',
    templateUrl: './form.component.html',
    styleUrls: ['./form.component.css']
})
export class FormComponent implements OnInit {
    form: FormModel;
    allVersions: FormModel[];
    allEditingGroups: EditingGroupModel[];
    formEditingGroups: { [key: string]: boolean };
    savingForm = false;
    createFormModalOpened = false;
    formSettingsModalOpened = false;

    constructor(private route: ActivatedRoute, private router: Router, private formService: FormService, private editingGroupService: EditingGroupService, private title: Title, public layoutService: LayoutService, public textValues: TextValues) { }

    ngOnInit(): void {
        this.title.setTitle(this.textValues.formBuilderPageTitle);
        this.route.paramMap.subscribe(paramMap => {
            const formIdentifier = paramMap.get('formIdentifier');
            const formVersion = +paramMap.get('formVersion');

            this.formService.getFormVersion(formIdentifier, formVersion).subscribe(version => {
                if (!this.form || this.form.FormIdentifier !== formIdentifier) {
                    this.fetchFormVersions(formIdentifier);
                    this.fetchAllEditingGroups().subscribe(response => {
                        this.formEditingGroups = this.getFormEditGroupMap(version);
                        this.allEditingGroups = response.data.editingGroups;
                    });
                }
                this.form = version;
            });
        });
    }

    onSaveFormTemplate(newFormDefinition: JSON): void {
        if (this.form.IsLockedForModification) {
            const message = 'This form cannot be modified. Would you like to create a new version of this form?';
            if (confirm(message)) {
                this.formService.createNewFormVersion(this.form.id, newFormDefinition).subscribe(form => {
                    this.form = form;
                    this.fetchFormVersions(this.form.FormIdentifier);
                    this.router.navigate([`forms/${this.form.FormIdentifier}/versions/${this.form.FormVersion}`]);
                });
            }
        } else {
            this.savingForm = true;
            this.form.Definition = newFormDefinition;
            this.formService.updateForm(this.form.id, this.form).subscribe(() => this.savingForm = false);
        }
    }

    onPublishFormVersion(): void {
        this.formService.publishFormVersion(this.form.id).subscribe(publishedForm => {
            if (publishedForm) {
                this.form = publishedForm;
                alert('The form has been published.');
            }
        });
    }

    onUnpublishFormVersion(): void {
        this.formService.unpublishFormVersion(this.form.id).subscribe(unpublishedForm => this.form = unpublishedForm);
    }

    updateFormSettings(): void {
        this.formService.updateForm(this.form.id, this.form).subscribe();
        this.closeFormSettingsModal()
    }

    // onEditingGroupAdded(group: EditingGroupModel): void {
    //     this.formService.addEditingGroupToForm(this.form.FormIdentifier, group.id).subscribe();
    // }

    // onEditingGroupRemoved(group: EditingGroupModel): void {
    //     this.formService.removeEditingGroupFromForm(this.form.FormIdentifier, group.id).subscribe();
    // }

    closeCreateFormModal(): void {
        this.createFormModalOpened = false;
    }

    openFormSettingsModal(): void {
        this.formSettingsModalOpened = true;
    }

    closeFormSettingsModal(): void {
        this.formSettingsModalOpened = false;
    }

    private fetchFormVersions(formIdentifier: string): void {
        this.formService.getVersionsByForm(formIdentifier).subscribe(response => {
            this.allVersions = response.data.forms;
        });
    }

    private fetchAllEditingGroups(): Observable<ApolloQueryResult<GraphQLDataResponse>> {
        return this.editingGroupService.getAllEditingGroups();
    }

    private getFormEditGroupMap(form: FormModel): { [key: string]: boolean } {
        return form.EditingGroups.reduce((prev, curr) => {
            prev[curr.id] = true;
            return prev;
        }, {} as { [key: string]: boolean });
    }
}

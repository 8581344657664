import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { ExpirationStatus } from 'src/app/enums/expiration-status';
import { SubmissionModel } from 'src/app/models/submission.model';
import { LayoutService } from 'src/app/services/layout.service';
import { SubmissionService } from 'src/app/services/submission.service';
import { TextValues } from 'src/app/shared/text-values';
import { Title } from '@angular/platform-browser';

@Component({
    selector: 'app-submission-list',
    templateUrl: './submission-list.component.html',
    styleUrls: ['./submission-list.component.css']
})
export class SubmissionListComponent implements OnInit {
    submissions: SubmissionModel[];
    currentPage: number;
    createFormModalOpened = false;
    itemsPerPage = 10;

    constructor(private router: Router, private route: ActivatedRoute, private submissionService: SubmissionService, private title: Title, public layoutService: LayoutService, public textValues: TextValues) { }

    ngOnInit(): void {
        this.route.queryParams.subscribe(params => {
            this.title.setTitle(this.textValues.submissionPageTitle);
            if (!params['page']) {
                this.navigateToPage(1);
            } else {
                this.currentPage = +params['page'];
            }
        });
        this.submissionService.getSubmissionsByUser().subscribe(response => {
            this.submissions = response.data.submissions.map(submission => new SubmissionModel(submission));
        });
    }

    navigateToPage(newPage: number): void {
        this.router.navigate([], {
            relativeTo: this.route,
            queryParams: { page: newPage.toString() },
            skipLocationChange: false
        });
    }

    calculateClasses(status: ExpirationStatus) {
        return {
            submitted: status === ExpirationStatus.Submitted,
            expired: status === ExpirationStatus.Expired,
            draft: status === ExpirationStatus.ExpiresToday
                || status === ExpirationStatus.Expiring
                || status === ExpirationStatus.NoExpireLimit
        };
    }

    openCreateFormModal(): void {
        this.createFormModalOpened = true;
    }

    closeCreateFormModal(): void {
        this.createFormModalOpened = false;
    }

}

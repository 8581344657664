import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { SubmissionService } from 'src/app/services/submission.service';
import { SubmissionModel } from 'src/app/models/submission.model';
import { TextValues } from 'src/app/shared/text-values';
import { ExpirationStatus } from 'src/app/enums/expiration-status';
import { LayoutService } from 'src/app/services/layout.service';

@Component({
  selector: 'answers',
  templateUrl: './answers.component.html',
  styleUrls: ['./answers.component.css']
})
export class AnswersComponent implements OnInit {
  formName: string;
  submissions: SubmissionModel[];
  currentPage: number;
  loadingAnswers = true;
  createFormModalOpened = false;
  itemsPerPage = 10;

  constructor(private router: Router, private route: ActivatedRoute, private submissionService: SubmissionService, public layoutService: LayoutService, public textValues: TextValues) { }

  ngOnInit(): void {
    this.route.queryParams.subscribe(params => {
      if (!params['page']) {
          this.navigateToPage(1);
      } else {
          this.currentPage = +params['page'];
      }
    });
    this.route.paramMap.subscribe(paramMap => {
      const requestedFormIdentifier = paramMap.get('formIdentifier');
      this.submissionService
        .getSubmissionsByForm(requestedFormIdentifier)
        .subscribe(submissions => {
          if (submissions && submissions.length > 0) {
            this.submissions = submissions;
            this.formName = this.submissions[0].Form.Name;
            this.loadingAnswers = false;
          }
        });
    });
  }

  navigateToPage(newPage: number): void {
    this.router.navigate([], {
        relativeTo: this.route,
        queryParams: { page: newPage.toString() },
        skipLocationChange: false
    });
  }

  openCreateFormModal(): void {
    this.createFormModalOpened = true;
  }

  closeCreateFormModal(): void {
    this.createFormModalOpened = false;
  }

  calculateClasses(status: ExpirationStatus) {
    return {
      submitted: status === ExpirationStatus.Submitted,
      expired: status === ExpirationStatus.Expired,
      draft: status === ExpirationStatus.ExpiresToday
        || status === ExpirationStatus.Expiring
        || status === ExpirationStatus.NoExpireLimit
    };
  }
}
